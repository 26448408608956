export interface IMenuItem {
  id?: string;
  src?: any;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  bool: boolean,
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    icon: 'fa fa-tachometer',
    label: 'NOC',
    to: '/noc',
    bool: false,
    src: '../../../assets/images/dashboard-icon.png',
  },
  {
    icon: 'fa fa-calculator',
    label: 'Cartridges',
    to: '/cartridge',
    bool: false,
    src: '../../../assets/images/Cat.png',
  },
  {
    icon: 'fa fa-calculator',
    label: 'eTrapps',
    to: '/etrapp',
    bool: false,
    src: '../../../assets/images/eTrapp_device_icon.png',
  },
  {
    icon: 'fa fa-book',
    label: 'Care Center',
    to: '/customer',
    bool: false,
    src: '../../../assets/images/customer-service.png',

  },
  {
    icon: 'fa fa-area-chart',
    label: 'Diagnostics',
    to: '/diagnostics',
    src: '',
    bool: false,
    subs: [
      {
        icon: 'fa-solid fa-ticket-simple',
        label: 'Cosmos Logs',
        to: '/diagnostics/cosmos-logs',
        bool: false,
      },
      {
        icon: 'fa-solid fa-receipt',
        label: 'Packet Tracer',
        to: '/diagnostics/packet-tracer',
        bool: false,
      },
      {
        icon: 'fa-solid fa-receipt',
        label: 'Analytics',
        to: '/diagnostics/analytics',
        bool: false,
      },
    ]

  },
  {
    icon: 'fa fa-hospital-o',
    label: 'Rewards',
    to: '/rewards',
    bool: false,
    src: '../../../assets/images/Financial-icon.png',

  },
  {
    icon: 'simple-icon-drawer',
    label: 'Fulfillment',
    to: '/fulfillment',
    bool: false,
    src: '',
    subs: [
      {
        icon: 'simple-icon-drawer',
        label: 'Fulfillment',
        to: '/fulfillment/fulfillment',
        bool: false,
      },
      {
        icon: 'simple-icon-drawer',
        label: 'Invoices',
        to: '/fulfillment/invoice',
        bool: false,
      },
    ]

  },
  {
    icon: 'fa fa-tasks',
    label: 'Customers',
    to: '/consumer',
    bool: false,
    src: '../../../assets/images/Layer35.png',

  },
  {
    icon: 'iconsminds-handshake',
    label: 'WholeSalers',
    to: '/wholesaler',
    bool: false,
    src: '',
  },
  {
    icon: 'iconsminds-network',
    label: 'Subscriptions',
    to: '/subscription',
    src: '',
    bool: false,
    subs: [
      {
        icon: 'iconsminds-administrator',
        label: 'Subscription Plan Type',
        to: '/subscription-plan-type',
        bool: false,
      },
      {
        icon: 'iconsminds-conference',
        label: 'Subscription',
        to: '/subscription',
        bool: false,
      },
    ]
  },
  {
    icon: 'iconsminds-hotel',
    label: 'Enterprise',
    to: '/enterprise',
    bool: false,
    src: '',
  },
  {
    icon: 'iconsminds-post-office',
    label: 'Pro',
    to: '/pro',
    bool: false,
    src: '',
  },
  {
    icon: 'fa fa-cog',
    label: 'Technicians',
    to: '/technician',
    bool: false,
    src: '../../../assets/images/Mens-icon.png',
  },
  {
    icon: 'fa fa-poll',
    label: 'Surveys',
    to: '/surveys',
    bool: false,
    src: '../../../assets/images/poll_icon.png',
  },
  {
    icon: 'fa fa-bill',
    label: 'Billing',
    to: '/billing',
    bool: false,
    src: '../../../assets/images/dollar_sign.png',
  },
  {
    icon: 'simple-icon-user',
    label: 'User Management',
    to: '/designation',
    src: '',
    bool: false,
    subs: [
      {
        icon: 'iconsminds-network',
        label: 'Designation',
        to: '/designation',
        bool: false,
      },
      {
        icon: 'iconsminds-administrator',
        label: 'Employees',
        to: '/employees',
        bool: false,
      },
      {
        icon: 'iconsminds-conference',
        label: 'Roles',
        to: '/roles',
        bool: false,
      },
      {
        icon: 'iconsminds-mens',
        label: 'Users',
        to: '/users',
        bool: false,
      },
    ]
  },
  {
    icon: 'fa fa-cog',
    label: 'Settings',
    to: '/settings',
    bool: false,
    src: '../../../assets/images/Gear-icon.png',
    subs: [
      {
        icon: 'simple-icon-question',
        label: 'FAQ Categories',
        to: '/settings/faq-category',
        bool: false,
      },
      {
        icon: 'simple-icon-bubbles',
        label: 'FAQs',
        to: '/settings/faqs',
        bool: false,
      },
      {
        icon: 'simple-icon-exclamation',
        label: 'Issues',
        to: '/settings/issues',
        bool: false,
      },
      {
        icon: 'simple-icon-basket',
        label: 'Order Items',
        to: '/settings/orders',
        bool: false,
      },
      {
        icon: 'simple-icon-bag',
        label: 'Redeemable Products',
        to: '/settings/products',
        bool: false,
      },
      {
        icon: 'simple-icon-camrecorder',
        label: 'Video Categories',
        to: '/settings/video-category',
        bool: false,
      },
      {
        icon: 'simple-icon-control-play',
        label: 'Videos',
        to: '/settings/videos',
        bool: false,
      },
      {
        icon: '',
        src: '../../../assets/images/eTrapp_device_icon.png',
        label: 'eTrapp Models',
        to: '/settings/etrapp-models',
        bool: false,
      },
      {
        icon: '',
        src: '../../../assets/images/eTrapp_device_icon.png',
        label: 'eTrapp Batches',
        to: '/settings/etrapp-batches',
        bool: false,
      },
      {
        icon: '',
        src: '../../../assets/images/Cat.png',
        label: 'Cartridge Models',
        to: '/settings/cartridge-models',
        bool: false,
      },
      {
        icon: 'fa fa-thermometer',
        label: 'Sensor Models',
        to: '/settings/sensor-models',
        bool: false,
      },
      {
        icon: 'fa fa-bell',
        label: 'Alert Types',
        to: '/settings/alert-types',
        bool: false,
      },
      {
        icon: 'simple-icon-bell',
        label: 'Alerts',
        to: '/settings/alerts',
        bool: false,
      },
      {
        icon: 'fa fa-moon-o',
        label: 'Theme Settings',
        to: '/settings/settings',
        bool: false,
      },
    ]
  },

];
export default data;
